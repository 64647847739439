<template lang="pug">
  div
    div#projectsWrapper
      p.center-loader(v-if="ready!='ready'")
        b-spinner.align-middle.mx-2
        strong Loading...
      div.projects(v-bind:class="ready")
        span#projectToggleBar(v-bind:class="projectToggleBar", v-on:click="clickAllProjects")
          span.link.small Back to All Projects
          br
          i.arrow-custom.up
        div#projectList.enforce-max-width(v-bind:class="projectListClass")
          b-row#projectListHeader
            b-col.fix-gutters(cols="12")
              p.d-inline-block.d-lg-none#projectListHeaderLabel A collection of personal and professional work.
              p.d-none.d-lg-inline-block#projectListHeaderLabel-lg A collection of personal and professional work.
              p.text-secondary-custom.d-inline-block.float-right#projectIconList
                b-img.icon.icon-filter.d-inline-block.ml-2(v-for="software in softwareList", :key="software", :src="getIconUrl(software)", :alt="software")
          b-row(cols="1", cols-sm="1", cols-md="3", cols-lg="4", align-v="stretch")
            b-card-group(v-for="project in projectList", :key="project.shortname")
              b-card.mb-5.mx-2.text-left.overflow-hidden.rounded-0.card-hover(no-body, :img-src="getImgUrl(project.bannerURL)", :img-alt="project.name", v-on:click="clickProject(project)", border-variant="light")
                b-card-body
                  b-card-title {{project.name}}
                  hr
                  b-card-text {{project.details}}
                b-button.py-3(block) View Project
        b-row#projectDetail.enforce-max-width(v-bind:class="projectDetailClass")
          b-col#projectHeader.text-left(cols="12")
            h4
              span.d-inline-block.mr-2 {{ curProject.name }}
              span.subtitle.d-inline-block(v-if="curProject.projecttype")
                b-icon-chevron-right.d-none.d-lg-inline.small
                span.mx-2 {{curProject.projecttype}}
              span.subtitle.d-inline-block
                b-icon-chevron-right.d-none.d-lg-inline.small
                b-img.icon.icon-filter.d-inline-block.ml-2(v-for="software in curProject.software", :key="software", :src="getIconUrl(software)", :alt="software")
            h5(v-if="curProject.credits")
              span.text-secondary-custom Credits:
              |&nbsp{{ curProject.credits }}
            p {{ curProject.details }}
            p(v-if="curProject.moredetails") {{ curProject.moredetails }}
          b-col.mb-3(cols="12", v-for="hero in curProject.heroes", :key="hero.itemid")
            b-embed(v-if="hero.mediatype==='video'", type="iframe", aspect="16by9", :src="hero.medialink", allowfullscreen)
            b-img(v-if="hero.mediatype==='image' && !hero.href", v-b-modal.media-item-modal, v-on:click="showMediaModal(hero)", :src="hero.mediathumbnail ? getImgUrl(hero.mediathumbnail) : getImgUrl(hero.medialink)", fluid, :alt="hero.itemtitle")
            b-img.pointer(v-if="hero.mediatype==='image' && hero.href", v-on:click="openPage(hero.href)", :src="hero.mediathumbnail ? getImgUrl(hero.mediathumbnail) : getImgUrl(hero.medialink)", fluid, :alt="hero.itemtitle")
          b-col.item-section.mb-2(cols="12", v-for="category in curProject.items", :key="category.itemid")
            span {{category.type}}
            hr
            b-row(cols="1", cols-sm="1", :cols-md="getCols('md', category.gridwidth, curProject.gridwidth)", :cols-lg="getCols('lg', category.gridwidth, curProject.gridwidth)", align-v="stretch")
              b-col.pb-4(v-for="item in category.items", :key="item.itemid")
                b-card.text-left.card-hover(v-on:click="showMediaModal(item)", overlay, :img-src="item.mediathumbnail ? getImgUrl(item.mediathumbnail) : getImgUrl(item.medialink)", :img-alt="item.itemtitle", text-variant="white", :title="item.itemtitle", :sub-title="item.mediatype")
                  b-card-text#subitem {{item.itemsubtitlepreview}}
        b-modal#media-item-modal(v-model="modalShow", :header-bg-variant="bgVariant", :body-bg-variant="bgVariant", :footer-bg-variant="bgVariant", centered)
          template(#modal-header="{ close }")
            h5 {{mediaTitle}}
            h5.pointer(@click="close()") Close
          b-embed(v-if="mediaType==='video'", type="iframe", aspect="16by9", :src="mediaLink", allowfullscreen)
          b-img(v-if="mediaType==='image' || mediaType==='gif'", :src="getImgUrl(mediaLink)", fluid, center, :alt="mediaTitle")
          span {{mediaSubtitle}}
</template>

<script>
import router from "../router";
export default {
  name: "Projects",
  data() {
    return {
      projectList: [],
      softwareList: [],
      defaultBanner: "../assets/images/projects/default-banner.jpg",
      projectListClass: "",
      projectDetailClass: "hide",
      projectToggleBar: "hide-toggle-bar",
      ready: "",
      curProject: "",
      mediaTitle: "",
      mediaType: "",
      mediaLink: "",
      mediaSubtitle: "",
      bgVariant: "dark",
      modalShow: false
    };
  },
  methods: {
    scrollToTop() {
      var container = this.$el.querySelector("#projectsWrapper");
      container.scrollTop = 0;
    },
    getImgUrl(pic) {
      return require("../assets/images/projects/" + pic);
    },
    getIconUrl(handle) {
      return require("../assets/images/projects/icons/" + handle + ".svg");
    },
    clickProject(project) {
      router.push({ path: `?${project.shortname}` });
      this.toggleProject(project);
    },
    toggleProject(project) {
      this.curProject = project;
      this.projectListClass = "hide"; //fade out projectList
      setTimeout(() => {
        this.projectListClass = "posthide"; //reset projectlist height
        this.scrollToTop();
      }, 750);
      this.projectDetailClass = "preshow"; //show empty project page
      setTimeout(() => {
        this.projectDetailClass = ""; //fade in project
      }, 750);
      this.projectToggleBar = "show-toggle-bar";
    },
    clickAllProjects: function() {
      router.push({ path: `/projects` });
      this.showProjects();
    },
    showProjects: function() {
      this.projectListClass = "";
      this.projectDetailClass = "hide";
      this.projectToggleBar = "hide-toggle-bar";
      this.scrollToTop();
    },
    forwardOrBack: function() {
      var query = this.getQuery();
      if (query) {
        this.goForward();
      } else {
        this.goBack();
      }
    },
    goForward: function() {
      this.processQuery();
    },
    goBack: function() {
      if (this.modalShow) {
        // if modal is open, close modal
        this.modalShow = false;
        this.showProjects();
      } else {
        this.showProjects();
      }
    },
    showMediaModal(item) {
      this.mediaTitle = item["itemtitle"];
      this.mediaType = item["mediatype"];
      this.mediaLink = item["medialink"];
      this.mediaSubtitle = item["itemsubtitle"];
      this.modalShow = true;
    },
    getCols(size, categoryGridWidth, projectGridWidth) {
      var gridWidth =
        categoryGridWidth != "" ? categoryGridWidth : projectGridWidth;
      if (gridWidth === "more-condensed") {
        if (size === "lg") {
          return 5;
        } else if (size === "md") {
          return 4;
        } else {
          return 1;
        }
      } else if (gridWidth === "condensed") {
        if (size === "lg") {
          return 4;
        } else if (size === "md") {
          return 3;
        } else {
          return 1;
        }
      } else {
        if (size === "lg") {
          return 3;
        } else if (size === "md") {
          return 2;
        } else {
          return 1;
        }
      }
    },
    getQuery() {
      var projectQuery = this.$route.query;
      for (var name in projectQuery) {
        if (name) {
          return true;
        }
      }
      return false;
    },
    processQuery() {
      var projectQuery = this.$route.query;
      for (var name in projectQuery) {
        if (name) {
          for (var i = 0; i < this.projectList.length; i++) {
            if (this.projectList[i].shortname === name) {
              this.toggleProject(this.projectList[i]);
            }
          }
        }
      }
    },
    openPage(href) {
      window.open(href, '_blank');
    }
  },
  created() {
    fetch(process.env.VUE_APP_PROJECTSHEET, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        var projectArray = [];
        var softwareArray = [];
        var softwareSet = new Set();
        var curProjectIndex = -1;
        var curItemCategoryIndex = 0;
        var maxSubtitleCharCount = 125;

        data.values.forEach(function(value, index) {
          if (value[0] === "TRUE") {
            var entry = {
              itemid: value[1].toLowerCase() + index,
              type: value[5],
              gridwidth: value[4],
              name: value[2],
              shortname: value[1].toLowerCase(),
              projecttype: value[3],
              details: value[14],
              software:
                value[17]
                  ? value[17].split(",")
                  : "",
              bannerURL:
                value[1].toLowerCase() + "-banner.jpg",
              credits: value[16],
              itemtitle: value[6],
              itemsubtitle: value[7],
              itemsubtitlepreview:
                value[7].length > maxSubtitleCharCount
                  ? value[7].substr(0, maxSubtitleCharCount) +
                    "..."
                  : value[7],
              mediatype: value[8],
              medialink: value[12],
              mediathumbnail: value[10],
              heroes: [],
              items: [],
              href: value[18],
              moredetails: value[15]
            };

            if (entry["type"] === "Project Info") {
              // Add to the set of projects
              projectArray.push(entry);
              curProjectIndex = curProjectIndex + 1;
              curItemCategoryIndex = 0;
              if (entry["software"][0].length > 0) {
                for (var i = 0; i < entry["software"].length; i++) {
                  softwareSet.add(entry["software"][i]);
                }
              }
            } else if (
              projectArray[curProjectIndex]["shortname"] === entry["shortname"]
            ) {
              if (entry["type"] === "Hero") {
                projectArray[curProjectIndex]["heroes"].push(entry);
              } else {
                if (
                  projectArray[curProjectIndex]["items"][curItemCategoryIndex]
                ) {
                  if (
                    projectArray[curProjectIndex]["items"][
                      curItemCategoryIndex
                    ]["type"] === entry["type"]
                  ) {
                    projectArray[curProjectIndex]["items"][
                      curItemCategoryIndex
                    ]["items"].push(entry);
                  } else {
                    curItemCategoryIndex = curItemCategoryIndex + 1;
                    projectArray[curProjectIndex]["items"].push(entry);
                    projectArray[curProjectIndex]["items"][
                      curItemCategoryIndex
                    ]["items"].push(entry);
                  }
                } else {
                  projectArray[curProjectIndex]["items"].push(entry);
                  projectArray[curProjectIndex]["items"][curItemCategoryIndex][
                    "items"
                  ].push(entry);
                }
              }
            }
          }
        });
        softwareArray = Array.from(softwareSet);
        softwareArray.sort();
        this.softwareList = softwareArray;
        this.projectList = projectArray;
        this.ready = "ready";
        this.processQuery();
        window.addEventListener("popstate", this.forwardOrBack);
      });
  }
};
</script>

<style scoped lang="less">
.card {
  border: none;
}

.pointer {
  cursor: pointer;
}

.projects {
  opacity: 0;

  -webkit-transition: opacity 2.8s;
  -moz-transition: opacity 2.8s;
  transition: opacity 2.8s;

  &.ready {
    opacity: 1;
  }
}

#projectList {
  overflow: visible;
  transform-origin: top;
  transform: translateY(0px);
  opacity: 1;
  z-index: 0;
  transition: all 0.5s ease;
  &.hide {
    transform: translateY(+22px);
    opacity: 0;
    overflow: hidden;
  }
  &.posthide {
    transform: translateY(+22px);
    opacity: 0;
    overflow: hidden;
    height: 0;
  }
}

#projectDetail {
  overflow: visible;
  padding-top: 38px;
  opacity: 1;
  z-index: 10;
  transition: all 0.5s ease;
  transform: translateY(0px);
  .card {
    .card-body {
      padding: 0.25rem 0.75rem 0.25rem;
      line-height: 20px;
      font-size: 16px;
      .card-title {
        margin-bottom: 0.45rem;
      }
      #subitem {
        padding-right: var(--gutters);
        position: absolute;
        bottom: 0.5rem;
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  &.hide {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    z-index: -100;
    height: 0;
    transform: translateY(400px);
  }
  &.preshow {
    opacity: 0;
    overflow: hidden;
    z-index: 0;
    transform: translateY(0px);
  }
  #projectHeader {
    h5 {
      font-variant: all-small-caps;
      font-weight: bold;
    }
  }
  .item-section {
    > span {
      display: block;
      font-weight: 700;
      font-family: var(--headings);
      font-variant: all-small-caps;
      font-size: larger;
      color: var(--secondary-color);
    }
    > hr {
      margin: 0 0 var(--gutters);
    }
  }
  .card-subtitle,
  .card-subtitle.text-muted {
    color: var(--secondary-color) !important;
    font-variant: all-small-caps;
    font-weight: bold;
    font-size: large;
  }
  .card-img-overlay {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5914740896358543) 0%,
      rgba(0, 0, 0, 0.29735644257703087) 44%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

#projectToggleBar {
  position: absolute;
  display: block;
  width: auto;
  min-width: fit-content;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
  transition: all 1s ease;
  background: var(--bg-color);
  padding: 16px 10px 0px;
  height: 40px;
  border-radius: 0px 0px 5px 5px;
  &.show-toggle-bar {
    top: -1px;
  }
  > span {
    border-top: var(--hr-width) solid var(--secondary-color);
    padding: 0px 5px 5px 5px;
  }
  > i {
    position: relative;
    top: -36px;
  }
}

#subitem {
  padding-right: var(--gutters);
  position: absolute;
  bottom: 16px;
}

#projectListHeaderLabel-lg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#projectListHeaderLabel {
  width: 100%;
}
</style>
